
import { Options, Vue } from 'vue-class-component'
import PlanStepCard from '@/components/PlanStepCard.vue'
import service from '@/utils/request'
import { ApplyStatus, Plan as PlanModel, PlanStatus, Task } from '@/models/plan'
import dayjs from 'dayjs'
import { AuthStore } from '@/store/modules/auth'
import { Dialog } from 'vant'
import wx from 'weixin-js-sdk-ts'

@Options({
  components: {
    PlanStepCard
  }
})
export default class Plan extends Vue {
  applyStatus = ApplyStatus
  planStatus = PlanStatus
  authStore = AuthStore
  plans: PlanModel[] = []
  teacherPlans: PlanModel[] = []
  plan: PlanModel = {
    cover: '',
    createTime: '',
    endTime: '',
    id: 0,
    introduction: '',
    isAllowRetrain: false,
    name: '',
    planContentList: [],
    planSurveyList: [],
    publishStatus: '',
    startTime: ''
  }

  applyNum = 0

  get isTeacher () {
    return AuthStore.isTeacher
  }

  getBackground (index: number) {
    return 'https://gx-static-new.oss-cn-beijing.aliyuncs.com/mindup/mp-pic/background-' + index % 5 + '.png'
  }

  statusStr (applyStatus: ApplyStatus) {
    if (applyStatus === ApplyStatus.AUDITING) {
      return '已申请的计划'
    } else if (applyStatus === ApplyStatus.AUDIT_PASSED) {
      return '执行中的计划'
    } else if (applyStatus === ApplyStatus.AUDIT_FAILED) {
      return '已拒绝的计划'
    }
  }

  mounted () {
    if (this.isTeacher) {
      this.loadNewApplyNum()
      this.loadTeacherPlans()
    } else {
      this.loadPlan()
    }
    this.loadPlans()
  }

  getDate (date: string) {
    return dayjs(date).format('M月D日')
  }

  loadPlans () {
    service.get('/mindup/mini/plans/available-plan', {
      params: { role: this.isTeacher ? 'TEACHER' : 'STUDENT' }
    }).then(res => {
      this.plans = res.data
    })
  }

  loadTeacherPlans () {
    service.get('/mindup/mini/plans/current-teacher-plan', {}).then(res => {
      this.teacherPlans = res.data
      const index = this.teacherPlans.findIndex(item => item.id === this.authStore.planId)
      if (index < 0) {
        this.authStore.setPlanId(0)
      }
    })
  }

  loadPlan () {
    service.get('/mindup/mini/plans/current-plan-info', {}).then(res => {
      if (res.data === null) {
        this.authStore.setCurrentPlanId('')
      } else {
        this.plan = res.data
        this.authStore.setCurrentPlanId(this.plan.id + '')
      }
    })
  }

  loadNewApplyNum () {
    service.get('/mindup/mini/plans/current-teacher-plan-apply-num', {}).then(res => {
      this.applyNum = res.data
    })
  }

  tapPlan (index: number) {
    if (this.authStore.name === undefined || this.authStore.name === 'string' || !this.authStore.name) {
      wx.miniProgram.navigateTo({ url: '/pages/login/login' })
    } else {
      this.$router.push({
        name: 'PlanDetail',
        params: { planId: this.plans[index].id, cover: this.getBackground(index) }
      })
    }
  }

  tapTeacherPlan (index: number) {
    this.$router.push({
      name: 'PlanTeacherDetail',
      params: { planId: this.teacherPlans[index].id }
    })
  }
}
